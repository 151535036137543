<template>
  <div>
    <b-row>
      <b-col>
        <app-collapse type="margin" class="mb-1">
          <!-- :isVisible="true" -->
          <app-collapse-item title="">
            <span slot="header" 
            class="lead collapse-title">{{ $t('customize.navigation_settings') }}
              <feather-icon
                v-b-tooltip.hover="{ variant: 'secondary' }"
                v-b-tooltip.hover.bottom="$t('customize.nav_settings_info')"
                icon="HelpCircleIcon"
                size="18"
                color="#3a71a3"
                class="cursor-pointer ml-1"
              />
            </span>
            <b-row>
              <b-col>
                <h4 class="customization-header"> 
                  <feather-icon
                    icon="GlobeIcon"
                    size="18"
                  />
                  {{ $t('customize.language') }}
                </h4>
                <div class="customization-container">
                  <b-form-group>
                    <b-form-checkbox-group
                      id="lang-checkbox"
                      v-model="langs_selected"
                      name="lang"
                      :state="langState"
                      class="demo-inline-spacing"
                    >
                      <b-form-checkbox v-for="option in langs"
                      :key="option.id"
                      :value="option">
                        {{ $t('lang.'+option.name) }}
                      </b-form-checkbox>
                      <b-form-invalid-feedback :state="langState">
                        {{$t('Please select one')}}
                      </b-form-invalid-feedback>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4 class="customization-header"> 
                  <feather-icon
                    icon="ImageIcon"
                    size="18"
                  />
                  {{ $t('customize.world_visualization') }}
                </h4>
                <div class="customization-container">
                  <b-img v-for="world in world_visualization" 
                    :key="world.id"
                    v-b-tooltip.hover="{ variant: 'secondary' }"
                    v-b-tooltip.hover.bottom="$t('world_visualization.'+world.name)"
                    :src="require('@/assets/images/world_visualization/WV_0'+world.id+'.png')" 
                    fluid :alt="world.name" 
                    rounded
                    :class="['pointer p-1 mr-1 world-item', isWorldChecked(world.id) ? 'world-checked': '']"
                    @click="selectWorld(world.id)"
                    
                  />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>

          <app-collapse-item title="" >
            <span slot="header" 
              class="lead collapse-title">{{ $t('customize.applications_settings') }}
              <feather-icon
                v-b-tooltip.hover="{ variant: 'secondary' }"
                v-b-tooltip.hover.bottom="$t('customize.content_settings_info')"
                icon="HelpCircleIcon"
                size="18"
                color="#3a71a3"
                class="cursor-pointer ml-1"
              />
            </span>
            <b-row>
              <b-col cols="12" sm="6">
                <h4 class="customization-header"> 
                  <feather-icon
                    icon="BookIcon"
                    size="18"
                  />
                  {{ $t('customize.reading_mode') }}
                </h4>
                <div class="customization-container">
                  <b-form-radio-group
                    v-model="reading_mode_selected"
                    :options="[
                      { id:0, name:this.$t('customize.read') },
                      { id:1, name:this.$t('customize.listen') },
                      { id:2, name:this.$t('customize.read_listen') },
                      { id:3, name:this.$t('customize.pictograms') },
                    ]"
                    class="mb-1"
                    value-field="id"
                    text-field="name"
                    stacked
                  />
                </div>
            
                <!-- font_type -->
                <h4 class="customization-header"> 
                  <feather-icon
                    icon="TypeIcon"
                    size="18"
                  />
                  {{ $t('customize.font_type') }}
                </h4>
                <div class="customization-container">
                  <b-form-radio-group
                    v-model="font_type_selected"
                    :options="[
                        { id:0, html: `<span style='font-family: Ubuntu'> ${this.$t('customize.standard')}</span>` },
                        { id:1, html: `<span style='font-family: Massallera'> ${this.$t('customize.linked')}</span>` },
                        { id:2, html: `<span style='font-family: CODEBold'> ${this.$t('customize.capital')}</span>` },
                        { id:3, html: `<span style='font-family: OpenDyslexic'> ${this.$t('customize.dyslexic')}</span>` }
                      ]"
                    class="mb-1"
                    value-field="id"
                    text-field="name"
                    stacked
                  />
                </div>
              </b-col>
              <b-col cols="12" sm="6">
                <!-- reading_level -->
                <h4 class="customization-header"> 
                  <feather-icon
                    icon="BookmarkIcon"
                    size="18"
                  />
                  {{ $t('customize.reading_level') }}
                </h4>
                <div class="customization-container">
                  <b-form-radio-group
                    v-model="reading_level_selected"
                    :options="[
                      { id:1, name: this.$t('Pre-A1') },
                      { id:2, name: 'A1' },
                      { id:3, name: 'A1+' },
                      { id:4, name: 'A2' },
                      { id:5, name: 'A2+' },
                      { id:6, name: 'B1' },
                      { id:7, name: 'B2' },
                      { id:8, name: 'C1' },
                    ]"
                    class="mb-1"
                    value-field="id"
                    text-field="name"
                    stacked
                  />
                </div>

                <!-- GAME MODE -->
                <h4 class="customization-header"> 
                  <feather-icon
                    icon="SmileIcon"
                    size="18"
                  />
                  {{ $t('customize.game_mode').toUpperCase() }}
                </h4>
                <div class="customization-container">
                  <b-form-radio-group
                    v-model="no_rush"
                    :options="[
                      { id:3, name:this.$t('customize.no_rush') },   // 3 - tranquilo
                      { id:0, name:this.$t('customize.with_time') }, // 0 - con tiempo 
                    ]"
                    class="mb-1"
                    value-field="id"
                    text-field="name"
                    stacked
                  />
                </div>
                  <!-- <div class="text-center">
                    <b-form-checkbox
                      style="margin-top: 1.5rem;"
                      v-model="no_rush"
                      checked="no_rush"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ $t('customize.no_rush') }}
                    </b-form-checkbox>
                  </div> -->
              </b-col>
            </b-row>

            <!-- difficulty_level -->
            <!-- <b-row>
              <b-col>
                <h4 class="customization-header"> 
                  <feather-icon
                    icon="BarChartIcon"
                    size="18"
                  />
                  {{ $t('customize.difficulty_level') }}
                </h4>
                <div class="customization-container">
                  <b-row>
                    <b-col md="8" cols="12">
                      <b-form-radio-group
                        v-model="difficulty_level_selected"
                        :options="difficulty_levels"
                        class="demo-inline-spacing mb-1"
                        value-field="id"
                        text-field="name"
                      />
                    </b-col>
                    <b-col md="4" cols="12" class="text-center">
                      <b-form-checkbox
                        style="margin-top: 1.5rem;"
                        v-model="no_rush"
                        checked="no_rush"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t('customize.no_rush') }}
                      </b-form-checkbox>
                    
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row> -->
          </app-collapse-item>

          <!-- ACCESSIBILITY -->
          <app-collapse-item title="">
            <span slot="header" 
            class="lead collapse-title">{{ $t('customize.accessibility_settings') }}
            <feather-icon
              v-b-tooltip.hover="{ variant: 'secondary' }"
              v-b-tooltip.hover.bottom="$t('customize.access_settings_info')"
              icon="HelpCircleIcon"
              size="18"
              color="#3a71a3"
              class="cursor-pointer ml-1"
            />
            </span>
            <b-row>
              <b-col>
                <div class="customization-container">
                  <b-form-group>
                    <b-form-checkbox-group
                      id="accessibility-checkbox"
                      v-model="accessibility"
                      name="accessibility"
                      class="demo-inline-spacing"
                    >
                      <b-form-checkbox v-for="option in accessibility_options"
                      :key="option.id"
                      :value="option">
                        {{ $t('customize.'+option.name) }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>

          <!-- GAMIFICATON -->
          <app-collapse-item title="">
            <span slot="header" 
            class="lead collapse-title">{{ $t('customize.gamification_settings') }}
            <feather-icon
              v-b-tooltip.hover="{ variant: 'secondary' }"
              v-b-tooltip.hover.bottom="$t('customize.gamification_settings_info')"
              icon="HelpCircleIcon"
              size="18"
              color="#3a71a3"
              class="cursor-pointer ml-1"
            />
            </span>
            <b-row>
              <b-col>
                <div class="customization-container">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="gamification"
                      name="check-button"
                      switch
                      inline
                    >
                      {{gamification? $t('fields.active') : $t('fields.inactive')}}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>

        </app-collapse>
      </b-col>
    </b-row>

    <!-- Action Buttons -->
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="button"
          @click="submitChanges"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.save_changes') }}
        </b-button>
      </b-col>
    </b-row>

    
  </div>
</template>

<script>
import { handleCustomization } from '@/api/routes'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { VBTooltip, BImg, BButton,
BRow, BCol, BFormGroup, BFormCheckbox, BFormCheckboxGroup,BFormRadioGroup,
BFormInvalidFeedback, BFormValidFeedback } from 'bootstrap-vue';

export default {
  components:{
    AppCollapse,
    AppCollapseItem,
    BRow, BCol, BImg, BButton,
    BFormGroup, BFormCheckbox, BFormCheckboxGroup,BFormRadioGroup,
    BFormInvalidFeedback, BFormValidFeedback
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props:{
    dreamers:{
      type: Array,
      required: true,
    },
    isGroup:{
      type: Boolean,
      required: true,
    },
    dataChanged:{
      type: Number,
      required: false
    }
  },
  data(){
    return {
      langs_selected:[],
      langs:[],

      selected_world:null,
      world_visualization:[
        { id:1, name:"space" },
        { id:2, name:"islands" },
        { id:3, name:"bigSpecial" },
        { id:4, name:"smallSpecial" },
        { id:5, name:"pixel3D" },
        { id:6, name:"small" },
        { id:7, name:"youth" },
        { id:8, name:"highcontrast" },
      ],

      reading_mode_selected:0,
      //reading_modes:,

      font_type_selected:0,
      //font_types:,

      difficulty_level_selected:1,
      /* difficulty_levels:[
        { id:1,name:this.$t("apps_levels.apprentice") },
        { id:2,name:this.$t("apps_levels.intermediate") },
        { id:3,name:this.$t("apps_levels.advanced") },
        { id:4,name:this.$t("apps_levels.expert") },
      ], */

      reading_level_selected:2,

      no_rush: 0, //modo tranquilo = true/false 
      /* 0 = tranquilo, 3=con tiempo */

      accessibility_options:[], //desde BD
      accessibility:[], //Array de opciones seleccionadas

      gamification: true,
    }
  },
  mounted(){
    this.getData()
  },
  watch:{
    dataChanged(){
      this.getData()
    }
  },
  
  computed: {
    langState() {
      return this.langs_selected.length >= 1
    },
  },

  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    selectWorld(world){
      this.selected_world=world
    },
    isWorldChecked(world){
      return this.selected_world == world
    },
    async getData(){
      let dreamers_ids = this.dreamers;
      // console.log(dreamers_ids, "dreamers_ids")
      let data = {
          'isGroup': this.isGroup,
          'ids': dreamers_ids,
      };
        /* this.langs =[{ id:1, name:"Spanish"}, { id:2, name:"English"}]
       this.accessibility_options =[{ id:1, name:"pagination"}, { id:2, name:"highlighting"}
       , { id:3, name:"speech_synthesis"}, { id:4, name:"speech_recognition"}
       , { id:5, name:"subtitles"}]  */
       
      await this.$http.post(handleCustomization, data).then( response => {
        // console.log("customization", response)

        if(response.status == 200){
        //CUSTOMIZATION DATA
          this.langs = response.data.all_langs //array de todos los idiomas

          /* Si no hay idiomas seleccionados, marcar todos como activos  */
          if(response.data.languages.length == 0){
            this.langs_selected = response.data.all_langs
          } else {
            this.langs_selected = response.data.languages //array de los idiomas seteados
          }

          this.selected_world = response.data.world_visualization // 1-7 o 0 si no hay ninguno seteado
          this.difficulty_level_selected = response.data.game_reading_level
          this.reading_level_selected = response.data.reading_level
          this.reading_mode_selected = response.data.tale_reading_mode
          this.font_type_selected = response.data.tale_font_type
          this.no_rush = response.data.game_mode // 0 = tranquilo, 3= con tiempo
          this.accessibility = response.data.accessibility
          this.accessibility_options = response.data.accessibility_options, //array de todos los accessibility where active = 1
          this.gamification = response.data.gamification == 1 ? true : false 
        } else {
          // console.log("error response.status", response.status)
          this.makeToast('danger', this.$t('Error'),this.$t('message.'+response.data.msg_code));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    async submitChanges(){
      let dreamers_ids = this.dreamers;

      if(this.langState){
    // // console.log("dreamers_ids", this.dreamers)
        let data = {
            'isGroup': this.isGroup,
            'ids': dreamers_ids,

            'langs': this.langs_selected,
            'world_visualization':this.selected_world,
            'game_reading_level':this.difficulty_level_selected,
            'reading_level': this.reading_level_selected,

            'tale_reading_mode':this.reading_mode_selected,
            'tale_font_type': this.font_type_selected,

            /* GAME MODE EN PLATAFORMA (no tiene sentido, pero asi es como esta)
              {
                  NONE = 0,  -->> "Tranquilo"
                  NO_RUSH = 3 -->> "Con tiempo"
              }
            */
            'game_mode':this.no_rush, // 0=tranquilo, 3=con tiempo
            'accessibility': this.accessibility,
            'gamification': this.gamification == true ? 1 : 0

        } 
        // // console.log(data)
        await this.$http.put(handleCustomization, data).then( response => {
          // // console.log(response)
          if(response.data.status == 200){
            this.validationErrorMessage = ''; 
            this.$emit('refetch-data')
            this.linked_accounts = response.data.linked_users
            this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
            this.$emit('close-modal')
          } else {
            this.validationErrorMessage = this.$t('message.'+response.data.msg_code)
            this.linked_accounts = response.data.linked_users
            this.makeToast('danger', this.$t('Error'),this.$t('message.'+response.data.msg_code));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      } else {
        console.log("error lang")
       this.makeToast('danger', this.$t('Error in language'),this.$t('Please select one')); 
      }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.world-checked{
  background-color: #d3eaff;
}
.world-item:hover{
  background-color: #87bced; 
}
.world-item:active{
  background-color: #d3eaff; 
}
</style>